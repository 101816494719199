import * as React from "react"
import Layout from "../components/Layout";
import Helmet from "react-helmet";
import { StaticImage } from 'gatsby-plugin-image'
import "../styles/templates/home.scss";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="title" content="osROOTs" />
        <meta name="description" content="osROOTs vous accompagne dans la migration et l’automatisation de vos applicatifs" />
        <title>osROOTs</title>
      </Helmet>
      <main>
        <section className="hero">
          <hgroup className="hero__content">
            <h1 className="huge-title">osROOTs vous accompagne dans la migration et l’automatisation de vos applicatifs</h1>
            <h2 className="intro">Vers une orchestration de conteneurs,
              dans le cloud ou dans votre infrastructure physique</h2>
          </hgroup>
          <StaticImage
            className="hero__image"
            src="../images/illuhero@300x.png"
            alt="osROOTs"
            loading="eager"
          />
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
